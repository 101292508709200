:root {
  --color-white: #fff;
  --color-black: #000;
  --color-gray-50: #f5f5f5;
  --color-gray-100: #e9ebee;
  --color-gray-200: #aaa;
  --color-gray-600: #737373;
  --color-gray-700: #495057;
  --color-gray-900: #212529;
}
