@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import 'variables-default';
@import 'variables';
@import '../node_modules/bootstrap/scss/bootstrap';
@import 'theme-default';
@import 'theme';

body {
  font:
    16px/1.5em 'Roboto',
    sans-serif;
  background: var(--color-gray-100);
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Lato', sans-serif;
  font-weight: bold;
}

a {
  color: var(--color-black);
  opacity: 0.8;
  text-decoration: none;
  cursor: pointer;
}

iframe {
  border: none;
}

.text-smaller {
  font-size: 0.85em;
}

.text-small {
  font-size: 0.5em;
}

#wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#content {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 1px 30px 0 rgb(0 0 0 / 15%);
  background: var(--color-white);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.btn-gf {
  color: var(--color-white);
  background-color: rgba(0, 0, 0, 0.9);
  border-color: rgba(0, 0, 0, 1);
  transition: all 0.5s ease-out;

  &:hover {
    color: var(--color-brand-900);
    background-color: rgba(0, 0, 0, 1);
    border-color: rgba(0, 0, 0, 1);
  }
}

.text-brand {
  color: var(--color-brand-900);
}

.qrCode {
  padding: 12px;
  opacity: 0.8;

  img {
    padding: 4px;
    width: 200px;
    border: 1px solid var(--color-gray-200);
  }
}

.content-box {
  border-radius: 8px;
  box-shadow: 0 1px 30px 0 rgb(0 0 0 / 15%);
  background: var(--color-white);
  width: 100%;
  padding: 0.8rem 0.5rem;
  margin: 0;
}

.form-control {
  height: 50px;
}

.full-screen-height-modal {
  height: calc(100vh - 120px);
}

.modal-dialog.full-screen-height-modal {
  .modal-content {
    height: 100%;
    max-height: calc(100vh - 60px);
    overflow-y: auto;
  }
}

#stip-container {
  height: 100%;

  iframe {
    width: 100%;
  }
}

@media (max-width: 767px) {
  h5 {
    font-size: 0.95rem;
  }
}

@media (min-width: 768px) {
  .full-screen-height-modal {
    height: calc(100vh - 60px);
  }
}

@media (min-width: 1024px) {
  .content-box {
    h2 {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: -48px;
        top: 10px;
        width: 30px;
        height: 5px;
        background: var(--color-brand-900);
      }
    }
  }
}

// Steps

.step {
  max-width: 430px;
  background: var(--color-gray-50);
  border-left: 5px solid var(--color-brand-900);
  padding: 25px 10px 25px 25px;
  margin-bottom: 25px;
  position: relative;
  display: flex;
  align-items: center;
}

.step::before,
.step::after {
  content: '';
  position: absolute;
}

.step::before {
  background-size: 80%;
  width: 40px;
  height: 40px;
  background: url(/assets/images/step-arrow.png) no-repeat;
  bottom: -50px;
  left: 15px;
}

.step::after {
  top: 0;
  left: 100%;
  height: 100%;
  width: 40px;
  background-image: linear-gradient(to top right, var(--color-gray-50) 50%, transparent 51%),
    linear-gradient(to bottom right, var(--color-gray-50) 50%, transparent 51%);
  background-size: 100% 51%;
  background-position:
    top left,
    bottom left;
  background-repeat: no-repeat;
}

.step:last-of-type::before {
  background-image: none;
}

.step .step-number {
  font-size: 50px;
  line-height: 1;
  margin-right: 25px;
}

.step p:last-of-type {
  margin-bottom: 0;
}

.step + .step {
  margin-left: 65px;
}

.step + .step + .step {
  margin-left: 130px;
}

.step + .step + .step + .step {
  margin-left: 195px;
}

.step-description h4 {
  font-size: 1.15em;
}

@media (max-width: 550px) {
  .step {
    margin-left: 0 !important;
    margin-right: 0 !important;
    max-width: 100%;
    width: 100%;
  }

  .mobile-spacer {
    margin-top: 20px;
    display: block;
  }

  .step::after,
  .step::before {
    display: none;
  }
}

// Tooltip

.tooltip {
  position: absolute;

  .tooltip-arrow {
    display: none;
  }
}
