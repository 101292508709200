$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1170px,
);

$utilities: (
  'opacity': (
    property: opacity,
    values: (
      0: 0,
      10: 0.1,
      25: 0.25,
      50: 0.5,
      75: 0.75,
      100: 1,
    ),
  ),
);
